<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Создание производителя</div>
                <div class="page__desc">Введите данные производителя</div>
            </div>
        </div>

        <div class="page__content">
            <form class="form-group" method="POST" action="" @submit.prevent="create()">

                <label for="title">Название</label>
                <div class="control-group">
                    <input
                            v-model="title"
                            id="title"
                            type="text"
                            name="title"
                            class="form-control"
                            required>
                </div>
                <br>

                <label for="country">Страна</label>
                <div class="control-group">
                    <input
                            v-model="country"
                            id="country"
                            type="text"
                            name="country"
                            class="form-control" >
                </div>
                <br>

                <input type="submit" class="btn btn-success btn-large" value="Сохранить">

            </form>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'CatalogManufacturerCreate',
        data() {
            return {
                title: null,
                country: null
            }
        },
        methods: {
            ...mapActions([
                'catalogManufacturersCreate',
            ]),
            async create() {
                const formData = new FormData();
                formData.append("title", this.title);
                formData.append("country",this.country);

                await this.catalogManufacturersCreate(formData)
            }
        }
    }
</script>